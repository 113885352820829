<template>
  <el-dialog
    v-model="showAddToDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-counterparty-addTo-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-counterparty-addTo-dialog-header__title">
        {{ t('button.addToCounterparty') }}
      </h4>
    </template>
    <el-form ref="addToCounterpartyFormRef" :model="addToCounterpartyForm" :rules="rules" label-position="top">
      <el-form-item :label="t('title.counterpartyName')" prop="counterpartyId">
        <el-select
          v-model="addToCounterpartyForm.counterpartyId"
          filterable
          remote
          reserve-keyword
          :loading="searchLoading"
          :remote-method="remoteMethod"
          :placeholder="t('placeholder.enterNameForSearch')"
          popper-class="elv-counterparty-addTo-dialog-popper"
        >
          <el-option
            v-for="(item, index) in counterpartyOptions"
            :key="index"
            :label="item.name"
            :value="item.counterpartyId"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="el-dialog__footer">
      <elv-button
        type="primary"
        round
        height="44"
        width="113"
        class="elv-account--dialog-footer__button"
        :loading="submitLoading"
        :disabled="addToCounterpartyForm.identity === ''"
        @click="onClickConnect"
        >{{ t('button.save') }}</elv-button
      >
    </div>
  </el-dialog>

  <ElvMessageBox
    ref="executeRuleMessageBoxRef"
    :confirm-button-text="t('button.Execute')"
    :cancel-button-text="t('button.later')"
    :title="t('title.executeIdentifyCounterpartyRules')"
    :loading="executeLoading"
    class="elv-counterparty-execute-message-box"
    @onConfirmEvent="onConfirmExecuteRule"
    @onCancelEvent="onCancelExecuteRule"
  >
    <template #content>
      <span
        v-dompurify-html="t('message.executeIdentifyCounterpartyRulesInfo')"
        class="elv-confirm-info-header__title"
      ></span
    ></template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import AccountsApi from '@/api/AccountsApi'
import TransactionsApi from '@/api/TransactionsApi'
import { useEntityStore } from '@/stores/modules/entity'
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const submitLoading = ref(false)
const executeLoading = ref(false)
const searchLoading = ref(false)
const showAddToDialog = ref(false)
const executeRuleMessageBoxRef = ref()
const addToCounterpartyFormRef = ref<FormInstance>()
const counterpartyOptions: any = ref([])
const originCounterpartyOptions: any = ref([])

const addToCounterpartyForm = reactive({
  platformId: '',
  identity: '',
  counterpartyId: ''
})

const rules = reactive<FormRules>({
  counterpartyId: {
    required: true,
    trigger: 'blur',
    message: 'Name is required'
  }
})

const emit = defineEmits(['resetList'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const remoteMethod = async (query: string) => {
  if (query) {
    try {
      searchLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list.filter((item: any) => item.type !== 'FEE')
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

const onCancelExecuteRule = () => {
  executeRuleMessageBoxRef.value?.onCheckMessageBoxDialog()
}

// 切换弹窗展示
const onCheckAddToDialog = () => {
  showAddToDialog.value = !showAddToDialog.value
}

const onCloseDialog = () => {
  originCounterpartyOptions.value = []
  addToCounterpartyFormRef.value?.resetFields()
}

const onConfirmExecuteRule = async () => {
  try {
    executeLoading.value = true
    await TransactionsApi.executeAllCounterpartyTransformerRule(entityId.value)
    onCancelExecuteRule()
    entityStore.fetchEntityDetail(entityId.value)
    ElMessage.success(t('message.success'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    executeLoading.value = false
  }
}

const onClickConnect = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.counterparty?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (!addToCounterpartyFormRef.value) return
  await addToCounterpartyFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        const params = {
          platformId: addToCounterpartyForm.platformId,
          identity: addToCounterpartyForm.identity
        }
        await AccountsApi.addToCounterparty(entityId.value, addToCounterpartyForm.counterpartyId, params)
        ElMessage.success(t('message.saveSuccess'))
        emit('resetList')
        onCancelExecuteRule()
        onCheckAddToDialog()
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

defineExpose({ onCheckAddToDialog })

watch(
  [() => showAddToDialog.value, () => props.currentData],
  async () => {
    if (showAddToDialog.value && !isEmpty(props.currentData)) {
      addToCounterpartyForm.platformId = props.currentData.platformId
      addToCounterpartyForm.identity = props.currentData.identity
      try {
        searchLoading.value = true
        const params = {
          limit: 20,
          page: 1
        }
        const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
        originCounterpartyOptions.value = data.list.filter((item: any) => item.type !== 'FEE')
        counterpartyOptions.value = data.list.filter((item: any) => item.type !== 'FEE')
      } catch (error) {
        console.log(error)
      } finally {
        searchLoading.value = false
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-counterparty-addTo-dialog {
  width: 620px;
  height: 230px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-counterparty-addTo-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-counterparty-addTo-dialog-content__platform {
      margin: 0 auto 16px;
      display: flex;
      width: 182px;
      height: 80px;
      padding: 0px 15px 0px 17px;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .el-textarea {
      width: 572px;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
        text-transform: capitalize;
      }

      &.elv-form-item__fileType {
        margin-bottom: 8px;
      }

      &.elv-account-form-item-platform {
        .el-form-item__content {
          width: 387px;
          margin-left: 8px;
          justify-content: space-between;
        }

        .el-form-item__label {
          width: 387px;
          margin-left: 8px;
          text-transform: capitalize;
        }
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }

    .el-select {
      width: 572px;

      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-counterparty-addTo-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }

    .elv-account-accountType-select-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;

      .el-form-item {
        margin-bottom: 0px;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-counterparty-addTo-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    .elv-counterparty-addTo-dialog-platform-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
